<template>
  <div class="promotion-banner">
    <div
      :class="{'promotion-banner-picture__enable': timer.hasOwnProperty('distance') && timer.distance > 0}"
      class="promotion-banner-picture"
    >
      <picture>
        <source
          :srcset="getPicturePath(currentPage.desktop_image)"
          :media="`(min-width: ${getBreakpointSm}px)`"
        >
        <img
          class="promotion-banner-picture__img"
          :class="{'action-has-image': (isDesktop && currentPage.desktop_image) || (!isDesktop && currentPage.mobile_image)}"
          :src="getPicturePath(currentPage.mobile_image)"
          :alt="currentPage.name"
          fetchpriority="high"
        >
      </picture>
      <div class="promotion-banner-picture-timer"
           v-if="timer.hasOwnProperty('distance') && timer.distance > 0"
      >
        {{ timer.days }} {{ labelDay(timer.days) }} {{ timer.hours }}:{{ timerMinutes }}:{{ timerSeconds }}
      </div>
    </div>
    <div class="promotion-banner-info">
      <SfHeading
        :class="{'promotion-banner__enable': timer.hasOwnProperty('distance') && timer.distance > 0}"
        :level="1"
        :title="currentPage.name"
      />
      <div
        :class="{'promotion-banner-info__text-enable': timer.hasOwnProperty('distance') && timer.distance > 0}"
        class="promotion-banner-info__text"
        v-html="currentPage.description"
      />
      <div
        v-if="timer.hasOwnProperty('distance') && timer.distance > 0"
        class="promotion-banner-date"
      >
        <p class="promotion-banner-date__time">
          {{ $t("from promotion") }}
          {{ formatDate(currentPage.date_from) }}
          {{ $t("By") }}
          {{ formatDate(currentPage.date_to) }}
        </p>
        <p
          v-if="actionTypeTitle"
          class="promotion-banner-date__type"
        >
          {{ actionTypeTitle }}
        </p>
        <div
          v-if="isShowCategory"
          class="promotion-banner-date__category"
        >
          {{ $t('Category') }}:
          <SfLink :link="getCategoryLink" class="sf-link--primary">{{ getCategoryName }}</SfLink>
        </div>
      </div>
      <div
        v-else
        class="promotion-banner-date"
      >
        {{ $t("Promotion ended") }}
      </div>
    </div>
  </div>
</template>
<script>

import { getThumbnailPath } from '@vue-storefront/core/helpers';
import { BREAKPOINT_SM } from 'theme/helpers/breakpoints';
import { SfHeading, SfLink } from '@storefront-ui/vue';
import { declension } from 'theme/helpers/text';
import DeviceType from 'theme/mixins/DeviceType';
import { formatRegionUrl } from '$modules/shipping/utils/region-url';

export default {
  name: 'MPromotionPageBanner',
  components: {
    SfHeading,
    SfLink
  },
  mixins: [DeviceType],
  props: {
    currentPage: {
      type: Object,
      default: () => ({})
    },
    timer: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    actionTypeTitle () {
      let title = ''

      switch (this.currentPage.action_type) {
        case '1': title = this.$t('Online only'); break;
        case '2': title = this.$t('Weekly variety'); break;
        default: title = ''
      }

      return title
    },
    getBreakpointSm () {
      return BREAKPOINT_SM
    },
    getCategoryName () {
      const category = this.getFirstCategory();
      return category?.name || '';
    },
    getCategoryLink () {
      const category = this.getFirstCategory();
      const id = category?.id || '';
      return id ? this.localizedRoute(formatRegionUrl(`/promotion?cat=${id}`)) : '';
    },
    isTimerOk () {
      return this.timer.hasOwnProperty('distance') && this.timer.distance > 0
    },
    isShowCategory () {
      return this.isTimerOk && this.getCategoryName
    },
    timerMinutes () {
      const minutes = this.timer.minutes
      return (minutes < 10) ? '0' + minutes : minutes
    },
    timerSeconds () {
      const seconds = this.timer.seconds
      return (seconds < 10) ? '0' + seconds : seconds
    }
  },
  methods: {
    getPicturePath (url) {
      const size = this.isDesktop ? 580 : 736
      return getThumbnailPath(url, size, size)
    },
    formatDate (date) {
      const newDate = new Date(date)
      const day = newDate.getDate()
      const month = newDate.toLocaleString('en', { month: 'long' })
      const year = newDate.getFullYear()
      return this.$t(`{day} ${month} {year}`, { day: day, year: year })
    },
    labelDay (day) {
      return declension([this.$t('day'), this.$t('days'), this.$t('days_')], day)
    },
    getFirstCategory () {
      return this.currentPage.categories?.[0] || null;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/mixins";
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";
@import '~theme/css/fonts';

.promotion-banner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 var(--spacer-10);
  margin: 0 0 50px;

  @media (min-width: $tablet-min) {
    padding: 0 var(--spacer-16);
  }

  @media (min-width: $desktop-min) {
    flex-direction: row;
  }

  &-picture {
    width: 100%;
    position: relative;
    height: px2rem(150);
    opacity: 0.5;

    &__enable {
      opacity: 1;
    }

    @media (min-width: $tablet-min) {
      height: px2rem(310);
    }

    @media (min-width: $desktop-min) {
      width: calc(50% - var(--spacer-10));
      height: px2rem(250);
    }

    &__img {
      width: 100%;
      height: 100%;
    }

    img.action-has-image {
      object-fit: cover;
      object-position: top;
    }

    &-timer {
      min-width: px2rem(110);
      max-width: px2rem(120);
      position: absolute;
      left: 0;
      top: var(--spacer-25);
      padding: var(--spacer-7) var(--spacer-8);
      background-color: var(--orange);
      font-size: var(--font-size-14);
      font-weight: 500;
      color: var(--white);

      span {
        display: none;
      }
    }
  }

  &-info {
    width: 100%;
    position: relative;

    @media (min-width: $tablet-min) {
      width: 80%;
      margin: auto;
    }

    @media (min-width: $desktop-min) {
      width: calc(50% - var(--spacer-10));
      margin: 0;
    }

    ::v-deep {
      .sf-heading__title {
        @include header-title;
        margin-top: var(--spacer-30);
        opacity: 0.5;

        @media (min-width: $tablet-min) {
          margin-top: 0;
        }
      }

      .promotion-banner__enable {
        .sf-heading__title {
          opacity: 1;
        }
      }
    }

    &__text {
      font-size: var(--font-size-13);
      line-height: var(--spacer-22);
      opacity: 0.5;

      &-enable {
        opacity: 1;
      }

      ::v-deep p {
        margin: var(--spacer-5) 0;

        @media (min-width: $tablet-min) {
          margin: var(--spacer-13) 0;
        }
      }

      @media (min-width: $tablet-min) {
        margin-top: var(--spacer-20);
        font-size: var(--font-size-14);
        line-height: var(--spacer-24);
      }
    }
  }

  &-date {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: var(--spacer-30);
    font-size: var(--font-size-14);

    &__category {
      width: auto;
      margin: var(--spacer-20) 0 0 var(--spacer-30);
      font-size: var(--font-size-14);

      @media (min-width: $tablet-min) {
        width: 100%;
        margin: var(--spacer-25) 0 0 0;
      }

      span {
        color: var(--orange);
      }
    }

    &__time {
      display: flex;
      align-items: center;
      width: 100%;
      margin: 0;
      font-size: var(--font-size-14);
      color: var(--dark-gray);
      text-transform: lowercase;
      @include font-icon(var(--icon-clock));

      @media (min-width: $tablet-min) {
        width: auto;
      }

      &:before {
        margin-right: var(--spacer-10);
        font-size: var(--font-size-20);
        color: var(--black);
      }
    }

    &__type {
      margin: var(--spacer-20) 0 0 0;
      display: flex;
      align-items: center;
      font-size: var(--font-size-14);
      @include font-icon(var(--icon-cart));

      @media (min-width: $tablet-min) {
        margin: 0 0 0 var(--spacer-30);
      }

      &:before {
        margin-right: var(--spacer-10);
        font-size: var(--font-size-20);
        color: var(--orange);
      }
    }
  }
}

</style>
